import { Box } from "@chakra-ui/react";
import React from "react";
import { getSrc } from "gatsby-plugin-image";
import styled from "styled-components";
import SEO from "../components/seo";
import SuperText from "../components/SuperText";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import Nav from "../components/Nav";

const Container = styled.div`
	display: flex;
	flex: 1;
	width: 100%;
`;

const Center = styled.div`
	width: 100%;
	max-width: 425px;
	margin: 0 auto;
	padding-left: 16px;
	padding-right: 16px;
`;
const ParaGraph = styled.div`
	margin-top: 50px;
	p {
		font-family: "fieldwork";
		font-weight: 300;
		font-style: normal;
		font-size: 1rem;
		line-height: 1.4;
		color: #1a1818;
		padding-bottom: 1rem;
	}
	strong {
		font-weight: 700;
	}
`;

const MyImage = styled.img`
	filter: blur(${(props) => props.blur}px);
`;

const isEven = (n) => {
	return n % 2 === 0;
};

const randomNumber = (min, max) => {
	return Math.floor(Math.random() * (max - min + 1)) + min;
};

const randomImages = (ar, size) => {
	let new_ar = [...ar];
	new_ar.splice(Math.floor(Math.random() * ar.length), 1);
	return ar.length <= size + 1 ? new_ar : randomImages(new_ar, size);
};

const randomRotation = () => {
	return randomNumber(-20, 20);
};

const randomBlur = () => {
	return randomNumber(0, 10);
};

export default function MikaMecTemplate({ page, allProducts }) {
    const breakpoints = useBreakpoint();
    
	const imagesForBg = allProducts.map(
		(item) =>
		getSrc(item.node.productData.productImage.localFile.childImageSharp.gatsbyImageData)
	);

	return (
		<>
			<SEO title={page.seo.title} description={page.seo.metaDesc} />
			<Nav />
			<Container>
				<Box
					position="relative"
					display={breakpoints.sm ? "none" : "flex"}
					flex={1}
					
				>
					{randomImages(imagesForBg, 7).map((pic, i) => {
						const blur = isEven(i) ? randomBlur() : 0
						return(
						<Box
							key={i}
							pos="absolute"
							height="250px"
							top={i * 200}
							left={isEven(i) ? "10px" : "auto"}
							right={!isEven(i) ? "10px" : "auto"}
							transform={`rotate(${randomRotation()}deg)`}
							zIndex={blur > 0 ? i : i+20}
						>
							<MyImage src={pic} blur={blur} />
						</Box>
						)
					})}
				</Box>
				<Center>
					<SuperText text="More" color="#fcafc0" />
					<SuperText text="(than)" color="#523178" />
					<SuperText text="Eight" color="#ff8f1c" />
					<SuperText text="Colours" color="#888d30" />

					<ParaGraph
						dangerouslySetInnerHTML={{ __html: page.content }}
					/>
				</Center>

				<Box
					position="relative"
					display={breakpoints.sm ? "none" : "flex"}
					flex={1}
				>
					{randomImages(imagesForBg, 7).map((pic, i) => {
						const blur = isEven(i) ? randomBlur() : 0
						return(
						<Box
							key={i}
							pos="absolute"
							height="250px"
							top={i * 200}
							left={isEven(i) ? "10px" : "auto"}
							right={!isEven(i) ? "10px" : "auto"}
							transform={`rotate(${randomRotation()}deg)`}
							zIndex={blur > 0 ? i : i+20}
						>
							<MyImage src={pic} blur={isEven(i) ? randomBlur() : 0} />
						</Box>
						)
					})}
				</Box>
			</Container>
		</>
	);
}